import _ from 'lodash'
import translations from '../../../utils/translations'
import { getGlobalDesignPresets } from '../preset/preset-service'
import {
  COMPONENT_TYPES,
  MULTI_STEP_DESIGN_GROUPS,
  CONTROLS_TYPES,
  CONTROLS_STATES,
  SECTIONS,
  COMP_PARTS,
  BASE_DESIGN_GROUPS,
} from '@wix/forms-common'
import {
  ROLE_FORM,
  ROLE_NEXT_BUTTON,
  ROLE_PREVIOUS_BUTTON,
  ROLE_SUBMIT_BUTTON,
  STEP_ROLE,
  THANK_YOU_STEP_ROLE,
} from '../../../constants/roles'

export const getGroupType = (group: DESIGN_GROUPS) => {
  const groupsManifest = getGroupsManifest()
  return groupsManifest[group].type
}

const getGroupsManifest = () => ({
  [BASE_DESIGN_GROUPS.INPUT_BACKGROUND]: {
    type: CONTROLS_TYPES.BG_COLOR_ALPHA,
    state: CONTROLS_STATES.REGULAR,
    compPart: COMP_PARTS.INPUTS,
  },
  [BASE_DESIGN_GROUPS.INPUT_BORDER_COLOR]: {
    type: CONTROLS_TYPES.BORDER_COLOR_ALPHA,
    state: CONTROLS_STATES.REGULAR,
    compPart: COMP_PARTS.INPUTS,
  },
  [BASE_DESIGN_GROUPS.INPUT_BORDER_SIZES]: {
    type: CONTROLS_TYPES.BORDER_SIZES,
    state: CONTROLS_STATES.REGULAR,
    compPart: COMP_PARTS.INPUTS,
  },
  [BASE_DESIGN_GROUPS.INPUT_BORDER_SIZES_ERROR]: {
    type: CONTROLS_TYPES.BORDER_SIZES,
    state: CONTROLS_STATES.ERROR,
    compPart: COMP_PARTS.INPUTS,
  },
  [BASE_DESIGN_GROUPS.INPUT_BORDER_SIZES_HOVER]: {
    type: CONTROLS_TYPES.BORDER_SIZES,
    state: CONTROLS_STATES.HOVER,
    compPart: COMP_PARTS.INPUTS,
  },
  [BASE_DESIGN_GROUPS.INPUT_BORDER_SIZES_FOCUS]: {
    type: CONTROLS_TYPES.BORDER_SIZES,
    state: CONTROLS_STATES.FOCUS,
    compPart: COMP_PARTS.INPUTS,
  },
  [BASE_DESIGN_GROUPS.INPUT_CORNERS]: {
    type: CONTROLS_TYPES.BORDER_RADIUS,
    state: CONTROLS_STATES.REGULAR,
    compPart: COMP_PARTS.INPUTS,
  },
  [BASE_DESIGN_GROUPS.PLACEHOLDER_TEXT_COLOR]: {
    type: CONTROLS_TYPES.TEXT_COLOR,
    state: CONTROLS_STATES.REGULAR,
    label: translations.t('manifest.globalDesign.placeholderTextColor'),
    section: SECTIONS.INPUT,
    compPart: COMP_PARTS.INPUTS,
  },
  [BASE_DESIGN_GROUPS.MAIN_TEXT_COLOR]: {
    type: CONTROLS_TYPES.TEXT_COLOR,
    state: CONTROLS_STATES.REGULAR,
    label: translations.t('manifest.globalDesign.InputTextColor'),
    section: SECTIONS.INPUT,
    compPart: COMP_PARTS.INPUTS,
  },
  [BASE_DESIGN_GROUPS.MAIN_TEXT_FONT]: {
    type: CONTROLS_TYPES.FONT,
    state: CONTROLS_STATES.REGULAR,
    section: SECTIONS.INPUT,
    compPart: COMP_PARTS.INPUTS,
  },
  [BASE_DESIGN_GROUPS.LABEL_TEXT_COLOR]: {
    type: CONTROLS_TYPES.TEXT_COLOR,
    state: CONTROLS_STATES.REGULAR,
    label: translations.t('manifest.globalDesign.labelTextColor'),
    section: SECTIONS.LABEL,
    compPart: COMP_PARTS.INPUTS,
  },
  [BASE_DESIGN_GROUPS.LABEL_TEXT_FONT]: {
    type: CONTROLS_TYPES.FONT,
    state: CONTROLS_STATES.REGULAR,
    section: SECTIONS.LABEL,
    category: 'text',
    compPart: COMP_PARTS.INPUTS,
  },
  [BASE_DESIGN_GROUPS.INPUT_BACKGROUND_HOVER]: {
    type: CONTROLS_TYPES.BG_COLOR_ALPHA,
    state: CONTROLS_STATES.HOVER,
    compPart: COMP_PARTS.INPUTS,
  },
  [BASE_DESIGN_GROUPS.INPUT_BORDER_COLOR_HOVER]: {
    type: CONTROLS_TYPES.BORDER_COLOR_ALPHA,
    state: CONTROLS_STATES.HOVER,
    compPart: COMP_PARTS.INPUTS,
  },
  [BASE_DESIGN_GROUPS.INPUT_BACKGROUND_FOCUS]: {
    type: CONTROLS_TYPES.BG_COLOR_ALPHA,
    state: CONTROLS_STATES.FOCUS,
    compPart: COMP_PARTS.INPUTS,
  },
  [BASE_DESIGN_GROUPS.INPUT_BORDER_COLOR_FOCUS]: {
    type: CONTROLS_TYPES.BORDER_COLOR_ALPHA,
    state: CONTROLS_STATES.FOCUS,
    compPart: COMP_PARTS.INPUTS,
  },
  [BASE_DESIGN_GROUPS.INPUT_BACKGROUND_ERROR]: {
    type: CONTROLS_TYPES.BG_COLOR_ALPHA,
    state: CONTROLS_STATES.ERROR,
    compPart: COMP_PARTS.INPUTS,
  },
  [BASE_DESIGN_GROUPS.INPUT_BORDER_COLOR_ERROR]: {
    type: CONTROLS_TYPES.BORDER_COLOR_ALPHA,
    state: CONTROLS_STATES.ERROR,
    compPart: COMP_PARTS.INPUTS,
  },
  [BASE_DESIGN_GROUPS.MAIN_TEXT_COLOR_ERROR]: {
    type: CONTROLS_TYPES.TEXT_COLOR,
    state: CONTROLS_STATES.ERROR,
    section: SECTIONS.LABEL,
    compPart: COMP_PARTS.INPUTS,
  },
  [BASE_DESIGN_GROUPS.FORM_BACKGROUND]: {
    type: CONTROLS_TYPES.BG_COLOR_ALPHA,
    state: CONTROLS_STATES.REGULAR,
    compPart: COMP_PARTS.CONTAINER,
  },
  [BASE_DESIGN_GROUPS.FORM_BORDER_COLOR]: {
    type: CONTROLS_TYPES.BORDER_COLOR_ALPHA,
    state: CONTROLS_STATES.REGULAR,
    compPart: COMP_PARTS.CONTAINER,
  },
  [BASE_DESIGN_GROUPS.FORM_BORDER_SIZE]: {
    type: CONTROLS_TYPES.BORDER_SIZE,
    state: CONTROLS_STATES.REGULAR,
    compPart: COMP_PARTS.CONTAINER,
  },
  [BASE_DESIGN_GROUPS.FORM_CORNERS]: {
    type: CONTROLS_TYPES.BORDER_RADIUS,
    state: CONTROLS_STATES.REGULAR,
    compPart: COMP_PARTS.CONTAINER,
  },
  [BASE_DESIGN_GROUPS.LABEL_REQUIRED_TEXT_COLOR]: {
    type: CONTROLS_TYPES.TEXT_COLOR,
    state: CONTROLS_STATES.REGULAR,
    label: translations.t('manifest.globalDesign.labelRequiredTextColor'),
    section: SECTIONS.LABEL,
    compPart: COMP_PARTS.INPUTS,
  },
  [BASE_DESIGN_GROUPS.INPUT_SHADOW]: {
    type: CONTROLS_TYPES.BOX_SHADOW,
    state: CONTROLS_STATES.REGULAR,
    compPart: COMP_PARTS.INPUTS,
  },
  [BASE_DESIGN_GROUPS.FORM_SHADOW]: {
    type: CONTROLS_TYPES.BOX_SHADOW,
    state: CONTROLS_STATES.REGULAR,
    compPart: COMP_PARTS.CONTAINER,
  },
})

export const createGlobalDesignManifest = ({ customHelpId, presetHelpId }) => ({
  groupMapping: {
    groups: getGroupsManifest(),
    compParts: [
      {
        label: translations.t('manifest.globalDesign.inputsCompPart'),
        value: COMP_PARTS.INPUTS,
        defaultSelected: true,
      },
      {
        label: translations.t('manifest.globalDesign.containerCompPart'),
        value: COMP_PARTS.CONTAINER,
      },
    ],
    sections: {
      [SECTIONS.INPUT]: {
        state: 'active',
        category: 'text',
        label: translations.t('manifest.globalDesign.inputSection'),
        priority: 2,
      },
      [SECTIONS.LABEL]: {
        state: 'active',
        category: 'text',
        label: translations.t('manifest.globalDesign.labelSection'),
        priority: 1,
      },
    },
  },
  presets: getGlobalDesignPresets(),
  title: translations.t('manifest.globalDesign.title'),
  presetsTitle: translations.t('manifest.globalDesign.presetsTitle'),
  customHelpId,
  presetHelpId,
})

export const createMultiStepGlobalDesignManifest = () => {
  const globalDesign = _.merge(
    {},
    createGlobalDesignManifest({
      customHelpId: '0f3c53ed-0f73-4492-b3bd-6217dc87b4fd',
      presetHelpId: 'c9f9aca6-1667-4c6c-949c-8e939c45db60',
    }),
    {
      groupMapping: {
        groups: {
          [MULTI_STEP_DESIGN_GROUPS.NEXT_BUTTONS_TEXT_COLOR]: {
            type: CONTROLS_TYPES.TEXT_COLOR,
            state: CONTROLS_STATES.REGULAR,
            compPart: COMP_PARTS.BUTTONS,
            section: SECTIONS.NEXT_BUTTONS,
          },
          [MULTI_STEP_DESIGN_GROUPS.PREVIOUS_BUTTONS_TEXT_COLOR]: {
            type: CONTROLS_TYPES.TEXT_COLOR,
            state: CONTROLS_STATES.REGULAR,
            compPart: COMP_PARTS.BUTTONS,
            section: SECTIONS.PREVIOUS_BUTTONS,
          },
          [MULTI_STEP_DESIGN_GROUPS.NEXT_BUTTONS_TEXT_COLOR_HOVER]: {
            type: CONTROLS_TYPES.TEXT_COLOR,
            state: CONTROLS_STATES.HOVER,
            compPart: COMP_PARTS.BUTTONS,
            section: SECTIONS.NEXT_BUTTONS,
          },
          [MULTI_STEP_DESIGN_GROUPS.PREVIOUS_BUTTONS_TEXT_COLOR_HOVER]: {
            type: CONTROLS_TYPES.TEXT_COLOR,
            state: CONTROLS_STATES.HOVER,
            compPart: COMP_PARTS.BUTTONS,
            section: SECTIONS.PREVIOUS_BUTTONS,
          },
          [MULTI_STEP_DESIGN_GROUPS.NEXT_BUTTONS_FONT]: {
            type: CONTROLS_TYPES.FONT,
            state: CONTROLS_STATES.REGULAR,
            compPart: COMP_PARTS.BUTTONS,
            section: SECTIONS.NEXT_BUTTONS,
          },
          [MULTI_STEP_DESIGN_GROUPS.PREVIOUS_BUTTONS_FONT]: {
            type: CONTROLS_TYPES.FONT,
            state: CONTROLS_STATES.REGULAR,
            compPart: COMP_PARTS.BUTTONS,
            section: SECTIONS.PREVIOUS_BUTTONS,
          },
          [MULTI_STEP_DESIGN_GROUPS.NEXT_BUTTONS_SHADOW]: {
            type: CONTROLS_TYPES.BOX_SHADOW,
            state: CONTROLS_STATES.REGULAR,
            compPart: COMP_PARTS.BUTTONS,
            section: SECTIONS.NEXT_BUTTONS,
          },
          [MULTI_STEP_DESIGN_GROUPS.PREVIOUS_BUTTONS_SHADOW]: {
            type: CONTROLS_TYPES.BOX_SHADOW,
            state: CONTROLS_STATES.REGULAR,
            compPart: COMP_PARTS.BUTTONS,
            section: SECTIONS.PREVIOUS_BUTTONS,
          },
          [MULTI_STEP_DESIGN_GROUPS.NEXT_BUTTONS_CORNERS]: {
            type: CONTROLS_TYPES.BORDER_RADIUS,
            state: CONTROLS_STATES.REGULAR,
            compPart: COMP_PARTS.BUTTONS,
            section: SECTIONS.NEXT_BUTTONS,
          },
          [MULTI_STEP_DESIGN_GROUPS.PREVIOUS_BUTTONS_CORNERS]: {
            type: CONTROLS_TYPES.BORDER_RADIUS,
            state: CONTROLS_STATES.REGULAR,
            compPart: COMP_PARTS.BUTTONS,
            section: SECTIONS.PREVIOUS_BUTTONS,
          },
          [MULTI_STEP_DESIGN_GROUPS.PREVIOUS_BUTTONS_BORDER_COLOR_HOVER]: {
            type: CONTROLS_TYPES.BORDER_RADIUS,
            state: CONTROLS_STATES.REGULAR,
            compPart: COMP_PARTS.BUTTONS,
            section: SECTIONS.PREVIOUS_BUTTONS,
          },
          [MULTI_STEP_DESIGN_GROUPS.PREVIOUS_BUTTONS_BORDER_COLOR]: {
            type: CONTROLS_TYPES.BORDER_COLOR_ALPHA,
            state: CONTROLS_STATES.REGULAR,
            compPart: COMP_PARTS.BUTTONS,
            section: SECTIONS.PREVIOUS_BUTTONS,
          },
          [MULTI_STEP_DESIGN_GROUPS.PREVIOUS_BUTTONS_BORDER_COLOR_HOVER]: {
            type: CONTROLS_TYPES.BORDER_COLOR_ALPHA,
            state: CONTROLS_STATES.HOVER,
            compPart: COMP_PARTS.BUTTONS,
            section: SECTIONS.PREVIOUS_BUTTONS,
          },
          [MULTI_STEP_DESIGN_GROUPS.NEXT_BUTTONS_BORDER_COLOR]: {
            type: CONTROLS_TYPES.BORDER_COLOR_ALPHA,
            state: CONTROLS_STATES.REGULAR,
            compPart: COMP_PARTS.BUTTONS,
            section: SECTIONS.NEXT_BUTTONS,
          },
          [MULTI_STEP_DESIGN_GROUPS.NEXT_BUTTONS_BORDER_COLOR_HOVER]: {
            type: CONTROLS_TYPES.BORDER_COLOR_ALPHA,
            state: CONTROLS_STATES.HOVER,
            compPart: COMP_PARTS.BUTTONS,
            section: SECTIONS.NEXT_BUTTONS,
          },
          [MULTI_STEP_DESIGN_GROUPS.NEXT_BUTTONS_BACKGROUND]: {
            type: CONTROLS_TYPES.BG_COLOR_ALPHA,
            state: CONTROLS_STATES.REGULAR,
            compPart: COMP_PARTS.BUTTONS,
            section: SECTIONS.NEXT_BUTTONS,
          },
          [MULTI_STEP_DESIGN_GROUPS.NEXT_BUTTONS_BACKGROUND_HOVER]: {
            type: CONTROLS_TYPES.BG_COLOR_ALPHA,
            state: CONTROLS_STATES.HOVER,
            compPart: COMP_PARTS.BUTTONS,
            section: SECTIONS.NEXT_BUTTONS,
          },
          [MULTI_STEP_DESIGN_GROUPS.PREVIOUS_BUTTONS_BACKGROUND]: {
            type: CONTROLS_TYPES.BG_COLOR_ALPHA,
            state: CONTROLS_STATES.REGULAR,
            compPart: COMP_PARTS.BUTTONS,
            section: SECTIONS.PREVIOUS_BUTTONS,
          },
          [MULTI_STEP_DESIGN_GROUPS.PREVIOUS_BUTTONS_BACKGROUND_HOVER]: {
            type: CONTROLS_TYPES.BG_COLOR_ALPHA,
            state: CONTROLS_STATES.HOVER,
            compPart: COMP_PARTS.BUTTONS,
            section: SECTIONS.PREVIOUS_BUTTONS,
          },
          [MULTI_STEP_DESIGN_GROUPS.NEXT_BUTTONS_BORDER_SIZE]: {
            type: CONTROLS_TYPES.BORDER_SIZE,
            state: CONTROLS_STATES.REGULAR,
            compPart: COMP_PARTS.BUTTONS,
            section: SECTIONS.NEXT_BUTTONS,
          },
          [MULTI_STEP_DESIGN_GROUPS.PREVIOUS_BUTTONS_BORDER_SIZE]: {
            type: CONTROLS_TYPES.BORDER_SIZE,
            state: CONTROLS_STATES.REGULAR,
            compPart: COMP_PARTS.BUTTONS,
            section: SECTIONS.PREVIOUS_BUTTONS,
          },
        },
        sections: {
          [SECTIONS.NEXT_BUTTONS]: {
            state: 'active',
            category: 'fill',
            label: translations.t('manifest.globalDesign.multiStepNextSection'),
            priority: 1,
          },
          [SECTIONS.PREVIOUS_BUTTONS]: {
            state: 'active',
            category: 'fill',
            label: translations.t('manifest.globalDesign.multiStepPreviousSection'),
            priority: 2,
          },
        },
      },
    },
  )
  globalDesign.groupMapping.compParts = [
    {
      label: translations.t('manifest.globalDesign.inputsCompPart'),
      value: COMP_PARTS.INPUTS,
      defaultSelected: true,
    },
    {
      label: translations.t('manifest.globalDesign.multiStepButtons'),
      value: COMP_PARTS.BUTTONS,
    },
    {
      label: translations.t('manifest.globalDesign.multiStepContainerCompPart'),
      value: COMP_PARTS.CONTAINER,
    },
  ]
  return globalDesign
}

const componentTypesDesignMapping: {
  [key in COMPONENT_TYPES]?: { [key in DESIGN_GROUPS]?: string[] }
} = {
  [COMPONENT_TYPES.FORM_CONTAINER]: {
    [BASE_DESIGN_GROUPS.FORM_BACKGROUND]: ['bg'],
    [BASE_DESIGN_GROUPS.FORM_BORDER_COLOR]: ['brd'],
    [BASE_DESIGN_GROUPS.FORM_BORDER_SIZE]: ['brw'],
    [BASE_DESIGN_GROUPS.FORM_CORNERS]: ['rd'],
  },
  [COMPONENT_TYPES.FORM_STATE]: {
    [BASE_DESIGN_GROUPS.FORM_CORNERS]: ['rd'],
  },
  [COMPONENT_TYPES.STATE_BOX]: {
    [BASE_DESIGN_GROUPS.FORM_BORDER_COLOR]: ['brd'],
    [BASE_DESIGN_GROUPS.FORM_BORDER_SIZE]: ['brw'],
    [BASE_DESIGN_GROUPS.FORM_SHADOW]: ['shd'],
    [BASE_DESIGN_GROUPS.FORM_CORNERS]: ['rd'],
  },
}

export const roleDesignMapping: {
  [role: string]: { [key in COMPONENT_TYPES]?: { [key in DESIGN_GROUPS]?: string[] } }
} = {
  [ROLE_FORM]: {
    [COMPONENT_TYPES.FORM_CONTAINER]: componentTypesDesignMapping[COMPONENT_TYPES.FORM_CONTAINER],
    [COMPONENT_TYPES.STATE_BOX]: componentTypesDesignMapping[COMPONENT_TYPES.STATE_BOX],
  },
  [ROLE_SUBMIT_BUTTON]: {
    [COMPONENT_TYPES.SITE_BUTTON]: {
      [MULTI_STEP_DESIGN_GROUPS.NEXT_BUTTONS_BACKGROUND]: ['bg'],
      [MULTI_STEP_DESIGN_GROUPS.NEXT_BUTTONS_BACKGROUND_HOVER]: ['bgh'],
      [MULTI_STEP_DESIGN_GROUPS.NEXT_BUTTONS_BORDER_COLOR]: ['brd'],
      [MULTI_STEP_DESIGN_GROUPS.NEXT_BUTTONS_BORDER_COLOR_HOVER]: ['brdh'],
      [MULTI_STEP_DESIGN_GROUPS.NEXT_BUTTONS_BORDER_SIZE]: ['brw'],
      [MULTI_STEP_DESIGN_GROUPS.NEXT_BUTTONS_CORNERS]: ['rd'],
      [MULTI_STEP_DESIGN_GROUPS.NEXT_BUTTONS_SHADOW]: ['shd'],
      [MULTI_STEP_DESIGN_GROUPS.NEXT_BUTTONS_TEXT_COLOR]: ['txt'],
      [MULTI_STEP_DESIGN_GROUPS.NEXT_BUTTONS_TEXT_COLOR_HOVER]: ['txth'],
      [MULTI_STEP_DESIGN_GROUPS.NEXT_BUTTONS_FONT]: ['fnt'],
    },
  },
  [ROLE_NEXT_BUTTON]: {
    [COMPONENT_TYPES.SITE_BUTTON]: {
      [MULTI_STEP_DESIGN_GROUPS.NEXT_BUTTONS_BACKGROUND]: ['bg'],
      [MULTI_STEP_DESIGN_GROUPS.NEXT_BUTTONS_BACKGROUND_HOVER]: ['bgh'],
      [MULTI_STEP_DESIGN_GROUPS.NEXT_BUTTONS_BORDER_COLOR]: ['brd'],
      [MULTI_STEP_DESIGN_GROUPS.NEXT_BUTTONS_BORDER_COLOR_HOVER]: ['brdh'],
      [MULTI_STEP_DESIGN_GROUPS.NEXT_BUTTONS_BORDER_SIZE]: ['brw'],
      [MULTI_STEP_DESIGN_GROUPS.NEXT_BUTTONS_CORNERS]: ['rd'],
      [MULTI_STEP_DESIGN_GROUPS.NEXT_BUTTONS_SHADOW]: ['shd'],
      [MULTI_STEP_DESIGN_GROUPS.NEXT_BUTTONS_TEXT_COLOR]: ['txt'],
      [MULTI_STEP_DESIGN_GROUPS.NEXT_BUTTONS_TEXT_COLOR_HOVER]: ['txth'],
      [MULTI_STEP_DESIGN_GROUPS.NEXT_BUTTONS_FONT]: ['fnt'],
    },
  },
  [ROLE_PREVIOUS_BUTTON]: {
    [COMPONENT_TYPES.SITE_BUTTON]: {
      [MULTI_STEP_DESIGN_GROUPS.PREVIOUS_BUTTONS_BACKGROUND]: ['bg'],
      [MULTI_STEP_DESIGN_GROUPS.PREVIOUS_BUTTONS_BACKGROUND_HOVER]: ['bgh'],
      [MULTI_STEP_DESIGN_GROUPS.PREVIOUS_BUTTONS_BORDER_COLOR]: ['brd'],
      [MULTI_STEP_DESIGN_GROUPS.PREVIOUS_BUTTONS_BORDER_COLOR_HOVER]: ['brdh'],
      [MULTI_STEP_DESIGN_GROUPS.PREVIOUS_BUTTONS_BORDER_SIZE]: ['brw'],
      [MULTI_STEP_DESIGN_GROUPS.PREVIOUS_BUTTONS_CORNERS]: ['rd'],
      [MULTI_STEP_DESIGN_GROUPS.PREVIOUS_BUTTONS_SHADOW]: ['shd'],
      [MULTI_STEP_DESIGN_GROUPS.PREVIOUS_BUTTONS_TEXT_COLOR]: ['txt'],
      [MULTI_STEP_DESIGN_GROUPS.PREVIOUS_BUTTONS_TEXT_COLOR_HOVER]: ['txth'],
      [MULTI_STEP_DESIGN_GROUPS.PREVIOUS_BUTTONS_FONT]: ['fnt'],
    },
  },
  [STEP_ROLE]: {
    [COMPONENT_TYPES.FORM_STATE]: componentTypesDesignMapping[COMPONENT_TYPES.FORM_STATE],
  },
  [THANK_YOU_STEP_ROLE]: {
    [COMPONENT_TYPES.FORM_STATE]: componentTypesDesignMapping[COMPONENT_TYPES.FORM_STATE],
  },
}
