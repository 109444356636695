import { FIELDS } from '../../../../../../constants/roles'
import { FormsFieldPreset } from '@wix/forms-common'
import { GeneralText } from './general-text'

// in use for payment form custom item (adding this field with the items the user given)
export class GeneralCustomAmount extends GeneralText {
  public get role() {
    return FIELDS.ROLE_FIELD_CUSTOM_AMOUNT
  }

  public get fieldType() {
    return FormsFieldPreset.GENERAL_CUSTOM_AMOUNT
  }

  public get manifest() {
    return {
      displayName: this.translate('manifest.paymentField.displayName'),
      behavior: {
        essential: {
          enabled: true,
          text: this.translate('manifest.paymentField.restoreText'),
        },
        duplicatable: false,
      },
    }
  }
}
