import _ from 'lodash'
import CoreApi from '../core-api'
import { withBi } from '../decorators'
import { EVENTS } from '../../../constants/bi'
import { createPanelDefs } from '../services/panel-definitions'
import { ROLE_FORM } from '../../../constants/roles'
import { PanelName, NativePanelName } from '@wix/forms-common'
import { nativePanelOverrides, excludeRolesFromHelpId } from './consts/native-panel-overrides'
import { getBaseUrl } from '../../../../src/utils/utils'
import { PANEL as ADI_PANEL } from '../../../panels/adi-panel/constants'
import { PanelEvent } from './types'
import { getFieldRenderConfigFields } from '../preset/fields/field-types-data'
import { InitialPanelData } from '../../../panels/commons/base-panel'
import { getFormPlugins } from '../plugins/utils'
import { FormPlugin, FormPreset, BillingPanelReferrer, UpgradeAlertType } from '@wix/forms-common'
import translations from '../../../utils/translations'
import { warningTypeToDefinition } from '../../../panels/warning-panel/warning-panel-definitions'
import { DELETE_ALERT_HELP_ID } from '../../../panels/form-settings-panel/components/payment/constants'

export default class ManagePanelsApi {
  private biLogger: any
  private boundEditorSDK: BoundEditorSDK
  private editorSDK: EditorSDK
  private coreApi: CoreApi
  private experiments: any
  private panelEvents: PanelEvent[]

  constructor(boundEditorSDK, editorSDK, coreApi: CoreApi, { experiments, biLogger }) {
    this.boundEditorSDK = boundEditorSDK
    this.coreApi = coreApi
    this.biLogger = biLogger
    this.editorSDK = editorSDK
    this.experiments = experiments
    this.panelEvents = []
  }

  public closePanel(panelToken, payload = null) {
    return this.editorSDK.editor.closePanel(panelToken, payload)
  }

  public openHelpPanel(helpId, onOpen = _.noop) {
    onOpen()
    return this.boundEditorSDK.editor.openHelpPanel({ helpId })
  }

  public async openModalPanel(componentRef, panelName: string, onOpen = _.noop) {
    const msid = await this.coreApi.getMetaSiteId()
    const isResponsive = this.coreApi.isResponsive()
    onOpen()
    return this.boundEditorSDK.editor.openModalPanel(
      createPanelDefs(msid)[panelName]({
        componentRef,
        experiments: this.experiments,
        isResponsive,
      }),
    )
  }

  public async openWarningModalPanel(componentRef, panelType: string) {
    const msid = await this.coreApi.getMetaSiteId()
    const panelDefinition = warningTypeToDefinition[panelType]

    return this.boundEditorSDK.editor.openModalPanel(
      createPanelDefs(msid)[PanelName.WARNING]({
        componentRef,
        ...panelDefinition,
        panelType,
      }),
    )
  }

  // _panelName is deprecated. ADI is loading one single page and we need to manage all panels there
  public openAdiPanel(
    _panelName: string,
    formRef: ComponentRef,
    activePanel = ADI_PANEL.EDIT_FORM,
  ) {
    return Promise.resolve(
      `https://${getBaseUrl()}/assets/statics/adi-panel.html?id=${formRef.id}&type=${
        formRef.type
      }&state=${activePanel}`,
    )
  }

  public openFieldPanel(componentRef: ComponentRef, fieldsPanelToken, _biData = {}) {
    return this._selectField(componentRef, fieldsPanelToken)
  }

  private _selectField(componentRef: ComponentRef, fieldsPanelToken) {
    this.closePanel(fieldsPanelToken)
    return this.boundEditorSDK.selection.selectComponentByCompRef({ compsToSelect: [componentRef] })
  }

  public async openContactSyncPanel(componentRef: ComponentRef) {
    const { msid, config, plugins } = await this._fetchInitialPanelData(componentRef)

    const panelDefinition = createPanelDefs(msid)[PanelName.CONTACT_SYNC_PANEL]({
      componentRef,
      config,
      plugins,
    })
    return this.boundEditorSDK.editor.openToolPanel(panelDefinition)
  }

  @withBi({ startEvid: EVENTS.PANELS.upgradeAlertPanel.ACTION_CLICK })
  public closeUpgradeAlertPanel(token, _biData = {}) {
    return this.closePanel(token)
  }

  public async openPremiumBillingPanel(
    componentRef: ComponentRef,
    { referrer, alertType }: { referrer: BillingPanelReferrer; alertType: UpgradeAlertType },
  ) {
    const esi = await this.coreApi.getEditorSessionId()
    const { ascendPlan } = await this.coreApi.premium.getCurrentAscendPlan()
    return this._openUpgradeAlertPanel(componentRef, alertType, {
      startBi: {
        form_comp_id: await this.coreApi.getFormId(componentRef),
        esi,
        origin: referrer,
        current_ascend_plan: ascendPlan,
      },
    })
  }

  @withBi({ startEvid: EVENTS.PANELS.upgradeAlertPanel.OPEN_PANEL })
  public async openAddFormPremiumBillingPanel(alertType, _biData = {}) {
    const msid = await this.coreApi.getMetaSiteId()

    return this.boundEditorSDK.editor.openModalPanel(
      createPanelDefs(msid)[PanelName.UPGRADE_ALERT]({
        alertType,
      }),
    )
  }

  @withBi({ startEvid: EVENTS.PANELS.manageSubscribersPanel.OPEN_PANEL })
  public openDefaultManageSubscribersPanel(msid, _biData = {}) {
    return this.boundEditorSDK.editor.openModalPanel(
      createPanelDefs(msid)[PanelName.FORM_MANAGE_SUBSCRIBERS](),
    )
  }

  public async openMemberSignupPagePanel() {
    const currentPageRef = await this.boundEditorSDK.document.pages.getCurrent()
    return this.boundEditorSDK.editor.openPagesPanel({
      pageRef: currentPageRef,
      initialSettingsTabType: <any>'PAGE_INFO',
    })
  }

  public openBusinessManagerPanel(entry) {
    return this.boundEditorSDK.editor.openDashboardPanel({ closeOtherPanels: false, url: entry })
  }

  @withBi({ startEvid: EVENTS.PANELS.addFieldPanel.SELECT_FIELD_TO_ADD })
  public openBlockedFieldAlert(componentRef: ComponentRef, { referrer, alertType }, _biData = {}) {
    return this.openPremiumBillingPanel(componentRef, { referrer, alertType })
  }

  @withBi({ endEvid: EVENTS.PANELS.fieldSettingsPanel.OPEN_PANEL })
  public async openDynamicFieldSettingsPanel(
    componentRef: ComponentRef,
    onOpen = _.noop,
    _biData = {},
  ) {
    const { role, controllerRef, config } = await this.coreApi.getComponentConnection(componentRef)
    const [formComponentRef, [{ data: fieldData }]] = await Promise.all([
      this.coreApi.findConnectedComponent(controllerRef, ROLE_FORM),
      this.boundEditorSDK.components.get({
        componentRefs: [componentRef],
        properties: ['data'],
      }),
    ])

    const options = {
      componentRef,
      ..._.get(nativePanelOverrides, role, {}),
    }

    if (!_.includes(excludeRolesFromHelpId, role)) {
      _.assign(options, { helpId: '779577ad-08d2-442a-b7d2-1052ff48a996' })
    }

    const onSettingsPanelClose = () =>
      this.coreApi.fields.getAndUpdateCrmLabel(componentRef, fieldData)

    this.boundEditorSDK.editor
      .openNativeComponentPanel(NativePanelName.SETTINGS, options)
      .then(onSettingsPanelClose)

    const formId = await this.coreApi.getFormId(formComponentRef)
    onOpen(_.merge({}, config, { formId }))
  }

  public async openComponentPanel<T>(
    componentRef: ComponentRef,
    panelName: string,
    onOpen = _.noop,
    extraInitialData: Partial<T> = {},
    onClose?: Function,
  ) {
    const isSiteSaved = await this.boundEditorSDK.info.isSiteSaved()
    if (!isSiteSaved) {
      await this.boundEditorSDK.editor.save()
    }

    const { msid, config, plugins, formId, preset } = await this._fetchInitialPanelData(
      componentRef,
    )

    const mode = await this.boundEditorSDK.info.getEditorMode()
    const fieldRenderConfig = config.fieldType
      ? getFieldRenderConfigFields(plugins, config.fieldType)
      : {}

    const initialData: InitialPanelData<T> = {
      componentRef,
      config,
      plugins,
      ...extraInitialData,
    }
    onOpen(_.merge({}, config, { formId, preset }))
    await this.boundEditorSDK.editor.openComponentPanel(
      createPanelDefs(msid)[panelName](initialData, {
        mode,
        experiments: this.experiments,
        isResponsive: this.coreApi.isResponsive(),
        fieldRenderConfig,
      }),
    )
    onClose && onClose()
  }

  @withBi({ startEvid: EVENTS.PANELS.upgradeAlertPanel.OPEN_PANEL })
  private async _openUpgradeAlertPanel(
    componentRef: ComponentRef,
    alertType: UpgradeAlertType,
    _biData = {},
  ) {
    const { msid, config, plugins } = await this._fetchInitialPanelData(componentRef)

    return this.boundEditorSDK.editor.openModalPanel(
      createPanelDefs(msid)[PanelName.UPGRADE_ALERT]({
        componentRef,
        config,
        plugins,
        alertType,
      }),
    )
  }

  public openPublishSitePopup(componentRef: ComponentRef, msid: string) {
    return this.boundEditorSDK.editor.openModalPanel(
      createPanelDefs(msid)[PanelName.PUBLISH_SITE_POP_UP](componentRef),
    )
  }

  @withBi({ startEvid: EVENTS.PANELS[PanelName.PAYMENT_WIZARD].PAYMENT_WIZARD_DISPLAYED })
  public openPaymentWizardPanel(componentRef: ComponentRef, msid, _biData = {}) {
    return this.boundEditorSDK.editor.openModalPanel(
      createPanelDefs(msid)[PanelName.PAYMENT_WIZARD](
        {
          componentRef,
        },
        { experiments: this.experiments },
      ),
    )
  }

  @withBi({
    startEvid: EVENTS.PANELS[PanelName.DELETE_PAYMENT_ALERT].REMOVE_PAYMENT_POPUP_DISPLAYED,
  })
  public openDeletePaymentPopup(_biData = {}) {
    return this.boundEditorSDK.editor.openErrorPanel({
      headerText: translations.t('removePaymentPanel.deletePaymentTitle'),
      shouldShowIllustration: true,
      topDescriptionText: '',
      bottomDescriptionText: translations.t('removePaymentPanel.alertDescription'),
      secondaryActionText: translations.t('removePaymentPanel.cancelButton'),
      mainActionText: translations.t('removePaymentPanel.removeButton'),
      helpId: DELETE_ALERT_HELP_ID,
    })
  }

  public openClosePaymentWizardAlertPopup(componentRef: ComponentRef, msid: string) {
    return this.boundEditorSDK.editor.openToolPanel(
      createPanelDefs(msid)[PanelName.CLOSE_PAYMENT_WIZARD_ALERT]({
        componentRef,
      }),
    )
  }

  @withBi({ startEvid: EVENTS.PANELS[PanelName.MANAGE_STEPS].OPEN_PANEL })
  public async openManageStepsPanel(componentRef: ComponentRef, _biData = {}) {
    const { msid, config, plugins } = await this._fetchInitialPanelData(componentRef)

    return this.boundEditorSDK.editor.openComponentPanel(
      createPanelDefs(msid)[PanelName.MANAGE_STEPS]({
        componentRef,
        config,
        plugins,
      }),
    )
  }

  public openDeleteRulePopup() {
    return this.boundEditorSDK.editor.openErrorPanel({
      headerText: translations.t('Conditional.RuleList.DeleteRulePopup.Title'),
      shouldShowIllustration: true,
      topDescriptionText: '',
      bottomDescriptionText: translations.t('Conditional.RuleList.DeleteRulePopup.Description'),
      secondaryActionText: translations.t('Conditional.RuleList.DeleteRulePopup.CancelButton'),
      mainActionText: translations.t('Conditional.RuleList.DeleteRulePopup.DeleteButton'),
    })
  }

  private async _fetchInitialPanelData(
    componentRef: ComponentRef,
  ): Promise<{
    msid: string
    config: ComponentConfig
    formComponentRef: ComponentRef
    formComponentConnection: ComponentConnection
    plugins: FormPlugin[]
    preset: FormPreset
    formId: string
  }> {
    const [msid, componentConnection] = await Promise.all([
      this.coreApi.getMetaSiteId(),
      this.coreApi.getComponentConnection(componentRef),
    ])

    const { role, controllerRef, config } = componentConnection
    let formComponentRef = componentRef
    let formComponentConnection = componentConnection

    if (role != ROLE_FORM) {
      formComponentRef = await this.coreApi.findConnectedComponent(controllerRef, ROLE_FORM)
      formComponentConnection = await this.coreApi.getComponentConnection(formComponentRef)
    }

    const plugins = getFormPlugins(formComponentConnection)
    const formId = await this.coreApi.getFormId(formComponentRef, formComponentConnection)
    return {
      msid,
      config,
      formComponentRef,
      formComponentConnection,
      plugins,
      preset: formComponentConnection.config.preset,
      formId,
    }
  }

  public registerPanelEvent(panelEvent: PanelEvent) {
    this.panelEvents.push(panelEvent)
  }

  public unregisterAllPanelEvents(panelToken: string) {
    this.panelEvents = this.panelEvents.filter((event) => event.panelToken != panelToken)
  }

  public triggerPanelEvent(eventName: string, payload) {
    this.panelEvents
      .filter((event) => event.eventName === eventName)
      .forEach((event) => event.callback(payload))
  }

  public getAllPanelEvents() {
    return this.panelEvents
  }

  public async closeWarningPanel(
    token: string,
    payload: any,
    shouldHide: boolean,
    panelKey: string,
  ): Promise<void> {
    if (shouldHide) {
      await this._saveHideWarningPanelInPreferences(panelKey)
    }
    this.closePanel(token, payload)
  }

  private _saveHideWarningPanelInPreferences(panelKey: string): Promise<void> {
    return this.boundEditorSDK.editor.userPreferences.site.set({
      [panelKey]: true,
    })
  }

  public async shouldHideWarningPanel(panelKey: string): Promise<boolean> {
    const preferences = await this.boundEditorSDK.editor.userPreferences.site.get([panelKey])
    return preferences[panelKey]
  }
}
